/** @format */

const $ = require("jquery");
require("leaflet-gesture-handling");
import L from "leaflet";
import icon from "../../assets/images/marker-icon.png";
import shadow from "../../assets/images/marker-shadow.png";

var mapElement = document.getElementById("map");
// var userLocation = JSON.parse(mapElement.dataset.userlocation);
// var latlng = userLocation.data.loc.split(',')
var mapLocation = [51.513588, 7.465298]; // latlng ? latlng : [51.481846, 7.216236];
var vendors = JSON.parse(mapElement.dataset.vendors);

var map = L.map("map", {
  zoomControl: false,
  gestureHandling: true,
  attributionControl: false,
  gestureHandlingText: {
    touch: "Zwei Finger zum Zoomen",
    scroll: "Strg + Scrollen zum Zoomen",
    scrollMac: "\u2318 + Scrollen zum Zoomen",
  },
}).setView(mapLocation, 13);

// Create additional Control placeholders
function addControlPlaceholders(map) {
  var corners = map._controlCorners,
    l = "leaflet-",
    container = map._controlContainer;

  function createCorner(vSide, hSide) {
    var className = l + vSide + " " + l + hSide;
    corners[vSide + hSide] = L.DomUtil.create("div", className, container);
  }

  createCorner("verticalcenter", "left");
  createCorner("verticalcenter", "right");
}
addControlPlaceholders(map);

L.control
  .zoom({
    position: "verticalcenterleft",
  })
  .addTo(map);

const customMarker = L.icon({
  iconUrl: icon,
  shadowUrl: shadow,
  iconSize: [30, 45],
  iconAnchor: [30, 45],
  popupAnchor: [-15, -25],
});

//Marker Layer
var markerLayer = new L.FeatureGroup();

vendors
  .filter((vendor) => {
    return vendor.latitude != undefined;
  })
  .forEach((vendor) => {
    var latlng = [vendor.latitude, vendor.longitude];
    var marker = L.marker(latlng, { icon: customMarker });
    markerLayer.addLayer(marker);
    var vendorPopup;
    if (vendor.web_url != "") {
      vendorPopup = `
      <p><a target="_blank" href="${vendor.web_url}">${vendor.name}</a><br/>
      ${vendor.street} <br/> ${vendor.zip} ${vendor.city}</p>
    `;
    } else {
      vendorPopup = `
    <p><a href="#stores-anchor">${vendor.name}</a><br/>
    ${vendor.street} <br/> ${vendor.zip} ${vendor.city}</p>
    `;
    }
    var popupOptions = {
      maxWidth: "500",
      className: "custom",
    };
    marker.bindPopup(vendorPopup, popupOptions).on("click", clickZoom);
  });

map.addLayer(markerLayer);

function clickZoom(e) {
  map.setView(e.target.getLatLng(), 15);
}

L.tileLayer(
  "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
  {
    attribution:
      'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    id: "mapbox/light-v10",
    minZoom: 8,
    maxZoom: 15,
    accessToken:
      "pk.eyJ1IjoibmR1aXR6IiwiYSI6ImNqbzRjMDhrMzEycjgzcG54amo1NXE3emUifQ.kxs37xVLn7BYEBpyr5FoXg",
  }
).addTo(map);

$("document").ready(function () {
  document.getElementById("map").style.zIndex = "1";
});
